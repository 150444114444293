import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { BaseHttpService } from '../base/base.service';
import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';
import { Notification } from './notification.model';
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseHttpService {
  private apiUrl = environment.apiUrl;

  constructor(
    protected override http: HttpClient,
    protected override loadingService: ProgressSpinnerService
  ) {
    super(http, loadingService);
  }

  getNotifcationsByUserUid(userUid: string): Observable<Notification[]> {
    return this.get<Notification[]>({
      url: `${this.apiUrl}/notifications/${userUid}`,
      loading: true,
    }).pipe(
      take(1),
      catchError((error) => {
        const errorMessage =
          'Error fetching notifications with for user with id: ' + userUid;
        console.error(errorMessage, error);
        // Handle the error, e.g., show an error message
        throw new Error(errorMessage);
      })
    );
  }

  getLimitedNotifcationsByUserUid(
    userUid: string,
    limit: number
  ): Observable<Notification[]> {
    return this.get<Notification[]>({
      url: `${this.apiUrl}/notifications/${userUid}?limit=${limit}`,
      loading: true,
    }).pipe(
      take(1),
      catchError((error) => {
        const errorMessage =
          'Error fetching notifications count with for user with id: ' +
          userUid;
        console.error(errorMessage, error);
        // Handle the error, e.g., show an error message
        throw new Error(errorMessage);
      })
    );
  }

  getNotifcationsCountByUserUid(
    userUid: string,
    seen: boolean
  ): Observable<number> {
    return this.get<number>({
      url: `${this.apiUrl}/notifications/${userUid}/count`,
      loading: false,
    }).pipe(
      catchError((error) => {
        const errorMessage =
          'Error fetching notifications count with for user with id: ' +
          userUid;
        console.error(errorMessage, error);
        // Handle the error, e.g., show an error message
        throw new Error(errorMessage);
      })
    );
  }

  updateNotificationSeenStatus(
    notificationId: number,
    seen: boolean
  ): Observable<any> {
    return this.put<any>({
      url: `${this.apiUrl}/notifications/${notificationId}?seen=${seen}`,
      loading: true,
    }).pipe(
      catchError((error) => {
        console.error('Error editing notifications status: ', error);
        // Handle the error, e.g., show an error message
        throw new Error();
      })
    );
  }
}
