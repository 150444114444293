import { Injectable, NgZone } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateProfile,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as auth from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, switchMap, take } from 'rxjs';

import { ProgressSpinnerService } from '../progress-spinner/progress-spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser$ = authState(this.auth);

  userData: any; // Save logged in user data
  constructor(
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public toastr: ToastrService,
    public auth: Auth,
    public loadingService: ProgressSpinnerService
  ) {}

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return sendEmailVerification(this.auth.currentUser!).then(() => {
      this.router.navigate(['/authentication/verify-email']);
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string, returUrl: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        this.toastr.error(error.message.replace('Firebase: ', ''));
      })
      .finally(() => {});
  }

  SignUp(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this.SendVerificationMail();
        this.toastr.success('User registered successfully!');
        return result;
      })
      .catch((error) => {
        this.toastr.error(error.message.replace('Firebase: ', ''));
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail)
      .then(() => {
        this.toastr.info('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        this.toastr.error(error);
      });
  }

  // Sign out
  logout() {
    return this.auth.signOut().then(() => {
      localStorage.clear();
      this.router.navigate(['landing/home']);
    });
  }

  async getProfilePic(uid: string) {
    // Create a reference with an initial file path and name
    // Create a reference to the file we want to download
    const storage = getStorage();
    const starsRef = ref(storage, 'images/profile/' + uid);

    // Get the download URL
    return getDownloadURL(starsRef)
      .then((url) => {
        return url;
      })
      .catch((error) => {
        console.log('catched error: ', error);
        return 'assets/images/profile/user-1.jpg';
      });
  }

  getIdToken(): Observable<string | undefined> {
    return this.currentUser$.pipe(
      switchMap((user) => {
        if (user) {
          return user.getIdToken();
        } else {
          return of(undefined);
        }
      })
    );
  }

  isUserLoggedIn(): boolean {
    if (this.auth.currentUser) return true;
    return false;
  }
}
