<mat-toolbar class="topbar">
  <!-- Mobile Menu -->
  <button mat-icon-button (click)="toggleMobileNav.emit()">
    <i-tabler name="menu-2" class="icon-20"></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!-- Publish Trip -->
  <!-- --------------------------------------------------------------- -->

  <span class="flex-1-auto"></span>
  <button mat-button color="primary" routerLink="/core/publish-trip">
    Publish a trip
    <mat-icon>add_circle_outline</mat-icon>
  </button>

  <button mat-button color="primary" routerLink="/core/search-trips">
    Search
    <mat-icon>search</mat-icon>
  </button>

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <button
    mat-icon-button
    [matMenuTriggerFor]="notificationsMenu"
    aria-label="Notifications"
  >
    <i-tabler
      class="d-flex"
      name="bell"
      matBadge="{{
        unseenNotificationsNumber > 0 ? unseenNotificationsNumber : null
      }}"
      matBadgeColor="primary"
    ></i-tabler>
  </button>
  <mat-menu #notificationsMenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="d-flex align-items-center p-x-32 p-y-16 ng-tns-c3216136785-7">
      <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
      <span class="m-l-auto">
        <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12"
          >{{ unseenNotificationsNumber }} new
        </span>
      </span>
    </div>
    <button
      class="mat-mdc-menu-item mdc-list-item p-x-32 p-y-16"
      role="menuitem"
      tabindex="0"
      aria-disabled="false"
      [ngStyle]="{
        'background-color': notification.seen ? '' : '#ecf2ff',
        'margin-bottom.px': 5
      }"
      *ngFor="
        let notification of myNotifications.slice(0, maxNumberOfNotifications)
      "
      (click)="notificationUtil.handleNotification(notification)"
    >
      <span class="mdc-list-item__primary-text">
        <div class="d-flex align-items-center">
          <mat-icon color="primary" matListItemIcon>{{
            notificationUtil.getIconName(notification.type)
          }}</mat-icon>
          <div class="m-l-16">
            <h5
              class="f-s-14 f-w-600 m-0 mat-subtitle-1"
              [innerHTML]="notification.content"
              matListItemTitle
            ></h5>
            <span>{{ getRelativeDate(notification.creationDateTime) }}</span>
          </div>
        </div>
      </span>
      <div matripple="" class="mat-ripple mat-mdc-menu-ripple"></div>
    </button>

    <div class="p-y-12 p-x-32">
      <button
        mat-stroked-button
        color="primary"
        class="w-100"
        [routerLink]="['/core/notifications']"
      >
        See all notifications
      </button>
    </div>
  </mat-menu>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <button
    mat-icon-button
    [matMenuTriggerFor]="profilemenu"
    aria-label="Notifications"
  >
    <div *ngIf="user$ | async as user">
      <img
        [src]="
          user.photoURL !== '' &&
          user.photoURL !== null &&
          user.photoURL !== undefined
            ? user.photoURL
            : 'assets/images/profile/user-1.jpg'
        "
        class="rounded-circle object-cover"
        width="35"
      />
    </div>
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <button mat-menu-item [routerLink]="['/core/profile']">
      <mat-icon class="d-flex align-items-center"
        ><i-tabler name="user" class="icon-18 d-flex"></i-tabler></mat-icon
      >My Profile
    </button>

    <div class="p-x-12 m-t-12">
      <a
        mat-stroked-button
        color="primary"
        class="w-100"
        (click)="authenticationService.logout()"
        >Logout</a
      >
    </div>
  </mat-menu>
</mat-toolbar>
