import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Admin',
    isAdmin: true,
  },
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: '/core/dashboard',
    isAdmin: true,
  },
  {
    navCap: 'Send',
  },
  {
    displayName: 'Search Trips',
    iconName: 'search',
    route: '/core/search-trips',
  },
  {
    displayName: 'My Reservations',
    iconName: 'package',
    route: '/core/my-reservations',
  },
  {
    navCap: 'Carry',
  },
  {
    displayName: 'Publish Trip',
    iconName: 'plus',
    route: '/core/publish-trip',
  },
  {
    displayName: 'My Trips',
    iconName: 'plane-departure',
    route: '/core/my-trips',
  },
  {
    navCap: 'Interact',
  },
  {
    displayName: 'Messages',
    iconName: 'message-2',
    route: '/core/chat',
  },
  {
    displayName: 'Notifications',
    iconName: 'notification',
    route: '/core/notifications',
  },
  {
    navCap: 'Help',
  },
  {
    displayName: 'FAQs',
    iconName: 'help-hexagon',
    route: '/core/faq',
  },
];
