import { Component, OnInit } from '@angular/core';
import { navItems } from './sidebar-data';
import { NavService } from '../../../services/nav.service';
import { User } from 'firebase/auth';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { UserFireStoreService } from 'src/app/shared/services/user/user-firestore.service';
import { NavItem } from './nav-item/nav-item';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  navItems = navItems;
  filteredNavItems: NavItem[] = [];

  user$ = this.userFireStoreService.currentUserProfile$;
  constructor(
    public navService: NavService,
    public authenticationService: AuthService,
    private userFireStoreService: UserFireStoreService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const isAdmin = !!idTokenResult.claims['admin'];
          this.filteredNavItems = navItems.filter((item) => {
            return !item.isAdmin || (item.isAdmin && isAdmin);
          });
        });
      } else {
        this.filteredNavItems = navItems.filter((item) => !item.isAdmin);
      }
    });
  }
}
