import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { take } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Notification } from 'src/app/shared/services/notification/notification.model';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { UserFireStoreService } from 'src/app/shared/services/user/user-firestore.service';
import { NotificationUtil } from 'src/app/shared/utils/notification-util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;
  user$ = this.userFireStoreService.currentUserProfile$;
  unseenNotificationsNumber: number;
  currentUserUid: string;
  myNotifications: Notification[] = [];
  maxNumberOfNotifications = 5;

  constructor(
    public dialog: MatDialog,
    public authenticationService: AuthService,
    private userFireStoreService: UserFireStoreService,
    private notificationService: NotificationService,
    private router: Router,
    public notificationUtil: NotificationUtil
  ) {}
  ngOnInit() {
    this.authenticationService.currentUser$.pipe(take(1)).subscribe((user) => {
      this.currentUserUid = user?.uid!;
      this.initNotifications(this.currentUserUid);
    });
  }

  initNotifications(userUid: string) {
    this.notificationService
      .getLimitedNotifcationsByUserUid(userUid, this.maxNumberOfNotifications)
      .subscribe((notifications) => {
        this.myNotifications = notifications;
        this.unseenNotificationsNumber = notifications.filter(
          (notification) => notification.seen === false
        ).length;
      });
  }

  getRelativeDate(creationDateTime: Date) {
    let myMoment: moment.Moment = moment(creationDateTime);
    return myMoment.fromNow();
  }
}
