export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAgQDcifyOA2arFvRQFRNkML6S2VS19Nd4",
    authDomain: "ouisend-dev.firebaseapp.com",
    projectId: "ouisend-dev",
    storageBucket: "ouisend-dev.appspot.com",
    messagingSenderId: "496669124058",
    appId: "1:496669124058:web:3aae2989408984de2a4ce3",
    measurementId: "G-7WLG00DBZX"
  },
  apiUrl: 'https://dev.ouisend.com/api', // Replace with domain API URL
  stripeTestKey:
    'pk_test_51NIcX3GhtFriZtSQw7LTMS5UB5GWKiJiIvujvTrIQGl4w00ZY57N7e8WEFA3IM2EwaSXOs8EOhxRaiKLyoTER2v400pTo4UCQh',
};
