import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, throwError } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { ProgressSpinnerService } from '../services/progress-spinner/progress-spinner.service';
import { Token } from '@angular/compiler';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private spinnerService: ProgressSpinnerService,
    private authService: AuthService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getIdToken().pipe(  
      switchMap((token) => {        
        // Clone the request and set the Authorization header
        var authRequest = request.clone();
        if (token !== 'empty') {
          authRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        return next.handle(authRequest).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              // Redirect to the login page for unauthorized requests
              this.router.navigate(['authentication/login']);
            } else if (error.status === 403) {
              // Redirect to a forbidden page for forbidden requests
              this.toastr.error('Access forbidden: ' + error.message);
              this.router.navigate(['/forbidden']);
            } else if (error.status === 404) {
              this.toastr.error('Resource not found: ' + error.message);
              this.router.navigate(['authentication/error']);
            } else if (error.status === 409) {
              this.toastr.warning('Conflict error: ' + error.error.message);
            } else if (error.status === 406) {
              this.toastr.error(
                'Illegal argument error: ' + error.error.message
              );
            } else if (error.status >= 500) {
              this.toastr.error('Server error occurred: ' + error.message);
            } else {
              this.toastr.error('Unknown error: ' + error.message);
            }
            return throwError(() => error);
          }),
          finalize(() => {
            this.spinnerService.hideSpinner();
          })
        );
      })
    );
  }
}
