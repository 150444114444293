import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification/notification.service';
import { Notification } from 'src/app/shared/services/notification/notification.model';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationUtil {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}
  getIconName(type: string): string {
    switch (type) {
      case 'CHAT_MESSAGE':
        return 'message';
      case 'RESERVATION_REQUEST':
        return 'person_add';
      case 'RESERVATION_ACCEPT':
        return 'check';
      case 'RESERVATION_DECLINE':
        return 'close';
      case 'RESERVATION_CANCEL':
        return 'cancel';
      case 'PACKAGE_CARRIER_CONFIRM_RECIPIENT':
        return 'markunread_mailbox';
      case 'PACKAGE_HANDED_TO_RECIPIENT':
        return 'check_circle';
      default:
        return 'notification'; // Default icon if the type is not recognized
    }
  }

  handleNotification(notification: Notification): void {
    if (!notification.seen) {
      this.updateNotificationStatus(notification, true);
    }
    switch (notification.type) {
      case 'CHAT_MESSAGE': {
        this.router.navigate(['core/chat', notification.relatedObjectId]);
        break;
      }
      case 'PACKAGE_HANDED_TO_RECIPIENT':
      case 'RESERVATION_REQUEST': {
        this.router.navigate([
          'core/my-trips',
          notification.relatedObjectId,
          'reservations',
        ]);
        break;
      }
      case 'RESERVATION_ACCEPT':
      case 'RESERVATION_DECLINE': {
        this.router.navigate(['core/my-reservations']);
        break;
      }
      case 'PACKAGE_CARRIER_CONFIRM_RECIPIENT': {
        this.router.navigate([
          'core/my-reservations',
          notification.relatedObjectId,
        ]);
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  private updateNotificationStatus(
    notification: Notification,
    status: boolean
  ) {
    this.notificationService
      .updateNotificationSeenStatus(notification.id, status)
      .pipe(take(1))
      .subscribe();
  }
}
