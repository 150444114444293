import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerComponent } from '../../components/progress-spinner/progress-spinner.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerService {
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = this.loadingSubject.asObservable();

  dialogRef: MatDialogRef<ProgressSpinnerComponent>;

  showSpinner(): void {
    this.loadingSubject.next(true);
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.loadingSubject.next(false);
    }, 500);
  }
}
