<mat-sidenav-container
  class="mainWrapper blue_theme light-theme"
  autosize
  autoFocus
  dir="ltr"
>
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->

  <mat-sidenav
    #sidenav
    mode="over"
    fixedInViewport="true"
    opened="false"
    position="end"
  >
    <div>
      <div class="branding">
        <a href="/">
          <img
            src="./assets/images/logos/logo_transparent.png"
            class="align-middle m-2"
            alt="logo"
            width="150"
          />
        </a>
      </div>

      <div class="p-x-16">
        <a
          href=""
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16"
        >
          <span class="f-s-15 f-w-500 m-l-8">Home</span>
        </a>

        <a
          href="/landing/faq"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16"
        >
          <span class="f-s-15 f-w-500 m-l-8">Help</span>
        </a>
        <a
          href="/landing/contact"
          class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16"
        >
          <span class="f-s-15 f-w-500 m-l-8">Contact</span>
        </a>

        <button
          mat-button
          color="primary"
          class="w-100 f-s-16 f-w-500 m-b-16"
          routerLink="/authentication/login"
        >
          Login
        </button>

        <button
          mat-flat-button
          color="primary"
          class="w-100"
          routerLink="/authentication/register"
        >
          Signup
        </button>
      </div>
    </div>
  </mat-sidenav>
  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper app-container">
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->

    <app-header (toggleMobileNav)="sidenav.toggle()"></app-header>

    <main class="content">
      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
    </main>
    <footer class="bg-primary p-y-30">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-5 col-lg-4 text-center">
            <span
              ><img src="assets/images/logos/favicon.png" alt="logo"
            /></span>
            <div class="f-s-14 m-t-8" style="color: white">
              All rights reserved by Ouisend.
            </div>
            <div>
              <a [routerLink]="['/landing/terms-and-conditions']">
                Terms and conditions
              </a>
            </div>
            <div>
              <a [routerLink]="['/landing/privacy-policy']"> Privacy Policy </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
