<mat-toolbar class="topbar">
  <div class="branding">
    <a href="/">
      <img
        src="./assets/images/logos/logo_transparent.png"
        class="align-middle m-2"
        alt="logo"
        width="150"
      />
    </a>
  </div>

  <span class="flex-1-auto"></span>
  <div class="d-none d-lg-flex">
    <button mat-button class="m-x-8 f-s-16 f-w-500" routerLink="/landing/home">
      Home
    </button>

    <button mat-button class="m-x-8 f-s-16 f-w-500" routerLink="/landing/faq">
      Help
    </button>

    <button
      mat-button
      class="m-x-8 f-s-16 f-w-500"
      routerLink="/landing/contact"
    >
      Contact
    </button>

    <button
      mat-button
      color="primary"
      class="m-x-8 f-s-16 f-w-500"
      routerLink="/authentication/login"
    >
      Login
    </button>
    <button
      mat-flat-button
      color="primary"
      class="m-l-10"
      routerLink="/authentication/register"
    >
      Sign Up
    </button>

    <!-- <button mat-icon-button >
      <i-tabler name="menu-2" class="icon-20"></i-tabler>
    </button> -->
  </div>

  <button mat-icon-button *ngIf="!isOver" (click)="toggleMobileNav.emit()">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>
