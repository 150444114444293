<div class="flex-layout">
  <app-branding></app-branding>
  <mat-nav-list class="sidebar-list">
    <app-nav-item *ngFor="let item of filteredNavItems" [item]="item">
    </app-nav-item>
  </mat-nav-list>
  <div class="p-24 m-t-auto profile-bar">
    <div class="bg-light-accent d-flex align-items-center rounded p-16">
      <div *ngIf="user$ | async as user">
        <img
          [src]="
            user.photoURL !== '' &&
            user.photoURL !== null &&
            user.photoURL !== undefined
              ? user.photoURL
              : 'assets/images/profile/user-1.jpg'
          "
          width="40"
          class="rounded-circle"
        />
      </div>
      <div class="m-l-16" *ngIf="user$ | async as user">
        <h5 class="mat-subtitle-2 f-w-600">{{ user.displayName }}</h5>
      </div>
      <div class="m-l-auto">
        <a
          mat-button-ripple-uninitialized=""
          mat-icon-button=""
          class="d-flex justify-content-center mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base"
          aria-disabled="false"
          mat-button-is-fab="false"
          (click)="authenticationService.logout()"
          ><span
            class="mat-mdc-button-persistent-ripple mdc-button__ripple"
          ></span
          ><span class="mdc-button__label"
            ><i-tabler
              name="power"
              class="text-primary icon-18 d-flex"
              _nghost-ng-c2034356942=""
              ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 6a7.75 7.75 0 1 0 10 0"></path>
                <path d="M12 4l0 8"></path>
              </svg> </i-tabler></span
          ><span class="mat-mdc-focus-indicator"></span
          ><span class="mat-mdc-button-touch-target"></span
        ></a>
      </div>
    </div>
  </div>
</div>
